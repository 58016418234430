
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

export default function Dashboard() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  if (localStorage.getItem("email") === null && isLoggedIn) {
    setIsLoggedIn(false);
  } else if (localStorage.getItem("email") !== null && !isLoggedIn) {
    setIsLoggedIn(true);
  }

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="flex flex-col items-c enter justify-center gap-2 w-full">
        <section className="min-h-[36vh] py-8 px-12 bg-orange-500 w-full">
          <div className="flex">
            <div className="w-1/2 flex-none text-white">
              <h1 className="text-5xl font-semibold">COD'HEM</h1>
              <h2 className="text-lg">
                Consolidated Database of High Entropy Materials
              </h2>
              <div className="flex gap-2 mt-6">
                {isLoggedIn ? (
                  <></>
                ) : (
                  <NavLink
                    to="/login"
                    className="py-2 px-6 my-auto rounded-md bg-purple-700 text-white font-light transition-all hover:cursor-pointer hover:bg-purple-800"
                  >
                    Login or Register
                  </NavLink>
                )}

                <NavLink
                  to="/database"
                  className={
                    "py-2 px-6 my-auto rounded-md hover:cursor-pointer" +
                    " " +
                    (isLoggedIn
                      ? "bg-purple-700 text-white font-light transition-all hover:bg-purple-800"
                      : "border-[1px] border-purple-700 text-purple-700 font-light transition-all hover:bg-purple-700 hover:text-white")
                  }
                >
                  Explore Literature Database
                </NavLink>
              </div>
            </div>
          </div>
        </section>
        {/* Responsive Container for Chart */}
        <div
          style={{
            position: "relative",
            paddingBottom: "56.25%", // 16:9 Aspect Ratio
            height: 0,
            overflow: "hidden",
            marginTop: "40px",
            marginLeft: "80px",
            border: "2px solid #e5e7eb",
          }}
        >
          <h1 className="flex justify-center items-center gap-2 ">MongoDB Atlas Chart</h1>
          <iframe
            style={{
              position: "absolute",
              top: "40px",
              left: 0,
              width: "90%",
              height: "100%",
              margin: "0 auto",
              background: "#F1F5F4",
              border: "none",
              borderRadius: "2px",
              boxShadow: "0 2px 10px 0 rgba(70, 76, 79, 0.2)",
            }}
            src="https://charts.mongodb.com/charts-upi-2-0-ixrnoxa/embed/dashboards?id=55376b0f-10c3-4289-8085-2d56708e1b12&theme=light&autoRefresh=true&maxDataAge=3600&showTitleAndDesc=false&scalingWidth=fixed&scalingHeight=fixed"
            title="MongoDB Atlas Chart"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <div className="mt-40 py-4 bg-neutral-100 border border-t-1 border-neutral-300 text-center font-light text-sm">
        Copyright All Rights Reserved 2024 by Clemson MCDC
      </div>
    </>
  );
}
