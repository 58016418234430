import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Layout from "./components/Layout";
import Error from "./components/Error";
import Welcome from "./components/Landing";
import Predict from "./components/Predict/Predict";
import Profile from "./components/Profile";
import NewsUpdates from "./components/NewsUpdates";
import Admin from "./components/Admin/Admin";
import Database from "./components/Database/Database";

import LoginV1 from "./components/Auth/Login"
import RegisterV1 from "./components/Auth/Register"
import ForgotPassword from "./components/Auth/ForgotPassword"
import ResetPassword from "./components/Auth/ResetPassword"
import VerificationPage from "./components/Auth/VerificationPage"
import RegistrationSuccessPage from "./components/Auth/RegistrationSuccessPage"
import HomePage from "./components/Home";
import Dashboard from "./components/Dashboard";
import ContactUs from "./components/ContactUs";

// Function to determine which component to render
const getDashboardComponent = () => {
  // Check the environment variable and render accordingly
  return process.env.REACT_APP_NAME === "COD'HEM" ? <Dashboard /> : <HomePage />;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <Error />,
    children: [
      { path: "", element: getDashboardComponent() },
      {
        path: "/home",
        element: <Welcome />,
      },
      {
        path: "/predict",
        element: <Predict />,
      },
      {
        path: "/database",
        element: <Database />,
      },
      {
        path: "/profile",
        element: <Profile />,
      },
      {
        path: "/news-updates",
        element: <NewsUpdates />,
      },
      {path: "contact-us", element: <ContactUs />},
    ],
  },
  {
    path: "/admin",
    element: <Admin />,
  },
  {
    path: "/login",
    element: <LoginV1 />,
    errorElement: <Error />,
  },
  {
    path: "/register",
    element: <RegisterV1 />,
    errorElement: <Error />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
    errorElement: <Error />,
  },
  {
    path: "/reset-password/:hash",
    element: <ResetPassword />,
    errorElement: <Error />,
  },
  {
    path: "/verifyemail/:hash",
    element: <VerificationPage />,
    errorElement: <Error />,
  },
  {
    path: "/registration-success",
    element: <RegistrationSuccessPage />,
    errorElement: <Error />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
